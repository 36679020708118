import gql from 'graphql-tag';

export const Login = gql`
  mutation login ($username: String!, $password: String!) {
    login (username: $username, password: $password)
  }
`;

export const RefreshToken = gql`
  mutation refreshToken {
    refreshToken
  }
`;

export const ChangePassword = gql`
  mutation changePassword ($password: String!) {
    changePassword (password: $password)
  }
`;

export const ValidateToken = gql`
  mutation validateToken ($token: String!) {
    validateToken (token: $token)
  }
`;

export const UpdateUser = gql`
  mutation updateUser ($id: Int!, $data: UserInput) {
    updateUser (id: $id, data: $data)
  }
`;

export const CreateUser = gql`
  mutation createUser ($data: UserInput) {
    createUser (data: $data)
  }
`;

export const RemoveFactory = gql`
  mutation removeFactory ($id: Int!) {
    removeFactory (id: $id)
  }
`;

export const UpdateFactory = gql`
  mutation updateFactory ($id: Int!, $data: FactoryInput) {
    updateFactory (id: $id, data: $data)
  }
`;

export const CreateFactory= gql`
  mutation createFactory ($data: FactoryInput) {
    createFactory (data: $data)
  }
`;

export const RemovePaperFactory = gql`
  mutation removePaperFactory ($id: Int!) {
    removePaperFactory (id: $id)
  }
`;

export const UpdatePaperFactory = gql`
  mutation updatePaperFactory ($id: Int!, $data: PaperFactoryInput) {
    updatePaperFactory (id: $id, data: $data)
  }
`;

export const CreatePaperFactory= gql`
  mutation createPaperFactory ($data: PaperFactoryInput) {
    createPaperFactory (data: $data)
  }
`;

export const RemoveClient = gql`
  mutation removeClient ($id: Int!) {
    removeClient (id: $id)
  }
`;

export const UpdateClient = gql`
  mutation updateClient ($id: Int!, $data: ClientInput) {
    updateClient (id: $id, data: $data)
  }
`;

export const CreateClient = gql`
  mutation createClient ($data: ClientInput) {
    createClient (data: $data)
  }
`;

export const RemoveClientTransport = gql`
  mutation removeClientTransport ($id: Int!) {
    removeClientTransport (id: $id)
  }
`;

export const UpdateClientTransport = gql`
  mutation updateClientTransport ($id: Int!, $data: ClientTransportInput) {
    updateClientTransport (id: $id, data: $data)
  }
`;

export const CreateClientTransport = gql`
  mutation createClientTransport ($data: ClientTransportInput!) {
    createClientTransport (data: $data)
  }
`;

export const RemoveClientContact = gql`
  mutation removeClientContact ($id: Int!) {
    removeClientContact (id: $id)
  }
`;

export const UpdateClientContact = gql`
  mutation updateClientContact($id: Int!, $data: ClientContactInput) {
    updateClientContact (id: $id, data: $data)
  }
`;

export const CreateClientContact = gql`
  mutation createClientContact ($data: ClientContactInput!) {
    createClientContact (data: $data)
  }
`;

export const RemoveBox = gql`
  mutation removeBox ($id: Int!) {
    removeBox (id: $id)
  }
`;

export const UpdateBox = gql`
  mutation updateBox ($id: Int!, $data: BoxInput) {
    updateBox (id: $id, data: $data)
  }
`;

export const CreateBox = gql`
  mutation createBox ($data: BoxInput) {
    createBox (data: $data)
  }
`;

export const RemoveClass = gql`
  mutation removeClass ($id: Int!) {
    removeClass (id: $id)
  }
`;

export const UpdateClass = gql`
  mutation updateClass ($id: Int!, $data: ClassInput!) {
    updateClass (id: $id, data: $data)
  }
`;

export const CreateClass = gql`
  mutation createClass ($data: ClassInput!) {
    createClass (data: $data)
  }
`;

export const RemoveExtraProduct = gql`
  mutation removeExtraProduct ($id: Int!) {
    removeExtraProduct (id: $id)
  }
`;

export const UpdateExtraProduct = gql`
  mutation updateExtraProduct ($id: Int!, $data: ExtraProductInput) {
    updateExtraProduct (id: $id, data: $data)
  }
`;

export const CreateExtraProduct = gql`
  mutation createExtraProduct ($data: ExtraProductInput) {
    createExtraProduct (data: $data)
  }
`;

export const RemoveUser = gql`
  mutation removeUser ($id: Int!) {
    removeUser (id: $id)
  }
`;

export const DuplicateProductionItem = gql`
  mutation duplicateProductionItem ($id: Int!) {
    duplicateProductionItem (id: $id)
  }
`;

export const DuplicateBufferItem = gql`
  mutation duplicateBufferItem ($id: Int!) {
    duplicateBufferItem (id: $id)
  }
`;

export const DuplicateOrder = gql`
  mutation duplicateOrder ($id: Int!) {
    duplicateOrder (id: $id)
  }
`;

export const SetProductionToOrder = gql`
  mutation setProductionToOrder ($ids: [Int!]!) {
    setProductionToOrder (ids: $ids)
  }
`;

export const SetBufferToOrder = gql`
  mutation setBufferToOrder ($ids: [Int!]!) {
    setBufferToOrder (ids: $ids)
  }
`;

export const ProductionToBuffer = gql`
  mutation productionToBuffer ($prodId: Int!) {
    productionToBuffer (prodId: $prodId)
  }
`;

export const SendOrderEmail = gql`
  mutation sendOrderEmail ($orderNo: Int!, $file: Upload!, $buffer: Boolean) {
    sendOrderEmail (orderNo: $orderNo, file: $file, buffer: $buffer)
  }
`;

export const SendBufferIssuedEmail = gql`
  mutation sendBufferIssuedEmail ($prodId: Int!, $file: Upload!) {
    sendBufferIssuedEmail (prodId: $prodId, file: $file)
  }
`;

export const CreateOrderRow = gql`
  mutation createOrderRow ($orderId: Int!, $data: OrderRowInput, $orderRowId: Int) {
    createOrderRow (orderId: $orderId, data: $data, orderRowId: $orderRowId) {
      ID
      ORDER_ID
      BOX
      MATERIAL
      MAT_PRICE
      L
      B
      H
      PRINT
      AMOUNT
      AL
      AB
      FPRICE
      PROFIT
      LPRICE
      BOXNAME
      MAT_L
      MAT_B
      NUUT1
      NUUT2
      NUUT3
      BOXES
      LISTS
      COLOR1
      COLOR2
      COLOR3
      COLOR4
      PANTONE1
      PANTONE2
      PANTONE3
      PANTONE4
      MATRIITS
      KLISHEE
      COMMENT
      PICTURE
      ROTATS
      STATUS
      PROD_DATE
      FILE1
      FILE2
      NUUT4
      NUUT5
      TRANSPORT
      H_EXTRA
      FACTORY
      SLITTER
      FLEXO
      TIGEL
      SRE
      INLINE
      SLOTTER
      KLIIM
      ALIIM
      _3LIIM
      LAMINAATOR
      TOOTLEMINE
      NUUDID
      PROD_CODE
      PRODUCTION_CODE
      MAT_TYPE
      CONSTR_NAME
      CONSTR_DATE
      VERONA
      TITAN
      CREATION_YEAR
      PER_SHEET
      RECEIVER
      LP_L
      LP_B
      TT_L
      TT_B
      CLASSTYPE
      DENSITY
      AFD
      FPR
      EKSTSEN
      PREMAID
    }
  }
`;

export const UpdateOrderRow = gql`
  mutation updateOrderRow ($id: Int!, $data: OrderRowInput) {
    updateOrderRow (id: $id, data: $data)
  }
`;

export const RemoveOrderRow = gql`
  mutation removeOrderRow ($id: Int!) {
    removeOrderRow (id: $id)
  }
`;

export const GetProductionCode = gql`
  mutation getProductionCode ($orderRowId: Int!, $area: Float!, $className: String!, $weight: Float!) {
    getProductionCode (orderRowId: $orderRowId, area: $area, className: $className, weight: $weight)
  }
`;

export const CreateProductionItem = gql`
  mutation createProductionItem ($orderId: Int!, $orderRowId: Int!, $clientOrderNr: String!, $delivery: Date!, $buffer: Boolean) {
    createProductionItem (orderId: $orderId, orderRowId: $orderRowId, clientOrderNr: $clientOrderNr, delivery: $delivery, buffer: $buffer)
  }
`;

export const UpdateProductionItem = gql`
  mutation updateProductionItem ($id: Int!, $data: ProductionInput) {
    updateProductionItem (id: $id, data: $data)
  }
`;

export const UpdateBufferItem = gql`
  mutation updateBufferItem ($id: Int!, $data: ProductionInput) {
    updateBufferItem (id: $id, data: $data)
  }
`;

export const RemoveProductionItem = gql`
  mutation removeProductionItem ($id: Int!) {
    removeProductionItem (id: $id)
  }
`;

export const RemoveBufferItem = gql`
  mutation removeBufferItem ($id: Int!) {
    removeBufferItem (id: $id)
  }
`;

export const UpdateMaterialOrder = gql`
  mutation updateMaterialOrder ($id: Int!, $data: MaterialOrderInput) {
    updateMaterialOrder (id: $id, data: $data)
  }
`;

export const UpdateMaterialOrderItem = gql`
  mutation updateMaterialOrderItem ($id: Int!, $data: MaterialOrderRowInput!) {
    updateMaterialOrderItem (id: $id, data: $data)
  }
`;

export const CreateMaterialOrder = gql`
  mutation createMaterialOrder {
    createMaterialOrder
  }
`;

export const CreateMaterialOrderItem = gql`
  mutation createMaterialOrderItem ($data: MaterialOrderRowInput!) {
    createMaterialOrderItem (data: $data)
  }
`;

export const RemoveMaterialOrderItem = gql`
  mutation removeMaterialOrderItem ($id: Int!) {
    removeMaterialOrderItem (id: $id)
  }
`;

export const RemoveMaterialOrder = gql`
  mutation removeMaterialOrder ($id: Int!) {
    removeMaterialOrder (id: $id)
  }
`;

export const CreatePrintout = gql`
  mutation createPrintout ($data: PrintoutInput!) {
    createPrintout (data: $data)
  }
`;

export const RemovePrintout = gql`
  mutation removePrintout ($id: Int!) {
    removePrintout (id: $id)
  }
`;

export const CreateInvoice = gql`
  mutation createInvoice ($data: InvoiceInput) {
    createInvoice (data: $data)
  }
`;

export const RemoveInvoice = gql`
  mutation removeInvoice ($id: Int!) {
    removeInvoice (id: $id)
  }
`;

export const CreateDeliveryNote = gql`
  mutation createDeliveryNote ($data: DeliveryNoteInput) {
    createDeliveryNote (data: $data)
  }
`;

export const RemoveDeliveryNote = gql`
  mutation removeDeliveryNote ($id: Int!) {
    removeDeliveryNote (id: $id)
  }
`;

export const CreateInvoiceRow = gql`
  mutation createInvoiceRow ($data: InvoiceRowInput!) {
    createInvoiceRow (data: $data)
  }
`;

export const RemoveInvoiceRow = gql`
  mutation removeInvoiceRow ($id: Int!) {
    removeInvoiceRow (id: $id)
  }
`;

export const CreateInvoiceExtraRow = gql`
  mutation createInvoiceExtraRow ($data: InvoiceExtraRowInput!) {
    createInvoiceExtraRow (data: $data)
  }
`;

export const RemoveInvoiceExtraRow = gql`
  mutation removeInvoiceExtraRow ($id: Int!) {
    removeInvoiceExtraRow (id: $id)
  }
`;

export const UpdateInvoice = gql`
  mutation updateInvoice ($id: Int!, $data: InvoiceInput!) {
    updateInvoice (id: $id, data: $data)
  }
`;

export const UpdateDeliveryNote = gql`
  mutation updateDeliveryNote ($id: Int!, $data: DeliveryNoteInput!) {
    updateDeliveryNote (id: $id, data: $data)
  }
`;

export const CreateDeliveryNoteRow = gql`
  mutation createDeliveryNoteRow ($data: DeliveryNoteRowInput!) {
    createDeliveryNoteRow (data: $data)
  }
`;

export const UpdateDeliveryNoteRow = gql`
  mutation updateDeliveryNoteRow ($id: Int!, $data: DeliveryNoteRowInput!) {
    updateDeliveryNoteRow (id: $id, data: $data)
  }
`;

export const RemoveDeliveryNoteRow = gql`
  mutation removeDeliveryNoteRow ($id: Int!) {
    removeDeliveryNoteRow (id: $id)
  }
`;

export const CreateOrder = gql`
  mutation createOrder ($data: OrderInput) {
    createOrder (data: $data)
  }
`;

export const UpdateOrder = gql`
  mutation updateOrder ($id: Int!, $data: OrderInput!) {
    updateOrder (id: $id, data: $data)
  }
`;

export const RemoveMaterial = gql`
  mutation removeMaterial ($factory: String!, $mark: String!) {
    removeMaterial (factory: $factory, mark: $mark)
  }
`;

export const CreateMaterial = gql`
  mutation createMaterial($data: MaterialInput) {
    createMaterial (data: $data)
  }
`;

export const UpdateMaterialGroup = gql`
  mutation updateMaterialGroup ($id: Int!, $data: MaterialGroupInput!) {
    updateMaterialGroup (id: $id, data: $data)
  }
`;

export const RemoveMaterialGroup = gql`
  mutation removeMaterialGroup ($id: Int!) {
    removeMaterialGroup (id: $id)
  }
`;

export const CreateMaterialGroup = gql`
  mutation createMaterial($data: MaterialGroupInput) {
    createMaterialGroup (data: $data)
  }
`;

export const UpdateMaterial = gql`
  mutation updateMaterial ($factory: String!, $mark: String!, $data: MaterialInput!) {
    updateMaterial (factory: $factory, mark: $mark, data: $data)
  }
`;

export const UpdateConstant = gql`
  mutation updateConstant ($id: Int!, $data: ConstantInput!) {
    updateConstant (id: $id, data: $data)
  }
`;

export const RemoveOrder = gql`
  mutation removeOrder ($id: Int!) {
    removeOrder (id: $id)
  }
`;

export const CreateMaterialDelivery = gql`
  mutation createMaterialDelivery ($data: MaterialDeliveryInput!) {
    createMaterialDelivery (data: $data)
  }
`;

export const UpdateMaterialDelivery = gql`
  mutation updateMaterialDelivery ($id: Int! $data: MaterialDeliveryInput!) {
    updateMaterialDelivery (id: $id, data: $data)
  }
`;

export const RemoveMaterialDelivery = gql`
  mutation removeMaterialDelivery ($id: Int!) {
    removeMaterialDelivery (id: $id)
  }
`;

export const UploadFile = gql`
  mutation singleUpload ($file: Upload!, $fileType: Int, $orderRowId: Int) {
    singleUpload (file: $file, fileType: $fileType, orderRowId: $orderRowId) {
      filename
    }
  }
`

export const RemoveFile = gql`
  mutation removeFile ($id: Int!) {
    removeFile (id: $id)
  }
`;

export const SendToBookkeeping = gql`
  mutation sendToBookkeeping ($id: Int!, $file: Upload!) {
    sendToBookkeeping (id: $id, file: $file)
  }
`;

export const ConstructorConfirm = gql`
  mutation constructorConfirm ($id: Int!, $receiver: String!) {
    constructorConfirm (id: $id, receiver: $receiver)
  }
`;

export const UpdateFactoryMaterialOrder = gql`
  mutation updateFactoryMaterialOrder ($id: Int! $arrival: Date, $completed: Date) {
    updateFactoryMaterialOrder (id: $id, arrival: $arrival, completed: $completed)
  }
`;

export const ImportMaterials = gql`
  mutation importMaterials ($file: Upload!) {
    importMaterials (file: $file) {
      message
      failed {
        FACTORY
        MARK
        error
      }
    }
  }
`;

export const ImportMaterialCodes = gql`
  mutation importMaterialCodes ($file: Upload!) {
    importMaterialCodes (file: $file) {
      message
      failed {
        NAME
        MARK
        NUUT
        error
      }
    }
  }
`;

export const IssueBufferToSales = gql`
  mutation issueBufferToSales ($bufferId: Int!, $data: ProductionInput!, $amount: Int!) {
    issueBufferToSales (bufferId: $bufferId, data: $data, amount: $amount)
  }
`;

export const CreateLocation = gql`
  mutation createLocation ($label: String!) {
    createLocation (label: $label)
  }
`;

export const UpdateLocation = gql`
  mutation updateLocation ($id: Int!, $label: String) {
    updateLocation (id: $id, label: $label)
  }
`;

export const RemoveLocation = gql`
  mutation removeLocation ($id: Int!) {
    removeLocation (id: $id)
  }
`;

export const CreateMachine = gql`
  mutation createMachine ($data: MachineInput!) {
    createMachine (data: $data)
  }
`;

export const UpdateMachine = gql`
  mutation updateMachine ($id: Int!, $data: MachineInput!) {
    updateMachine (id: $id, data: $data)
  }
`;

export const RemoveMachine = gql`
  mutation removeMachine ($id: Int!) {
    removeMachine (id: $id)
  }
`;

export const CreatePlan = gql`
  mutation createPlan ($data: PlanInput!) {
    createPlan (data: $data)
  }
`;

export const UpdatePlan = gql`
  mutation updatePlan ($id: Int!, $data: PlanInput!) {
    updatePlan (id: $id, data: $data)
  }
`;

export const RemovePlan = gql`
  mutation removePlan ($id: Int!) {
    removePlan (id: $id)
  }
`;

export const CreateBlueprint = gql`
  mutation createBlueprint ($data: BlueprintInput!) {
    createBlueprint (data: $data)
  }
`;

export const UpdateBlueprint = gql`
  mutation updateBlueprint ($id: Int!, $data: BlueprintInput!) {
    updateBlueprint (id: $id, data: $data)
  }
`;

export const UpdateClientBoxcode = gql`
  mutation updateClientBoxcode ($prodId: Int, $bufferId: Int, $code: String) {
    updateClientBoxcode (prodId: $prodId, bufferId: $bufferId, code: $code)
  }
`;